(function () {
  'use strict';

  class StudentsCtrl {
    constructor(User, $stateParams, $document, Utils) {
      let vm = this;
      vm.$document = $document[0];
      vm.User = User;
      vm.Utils = Utils;
      vm.$stateParams = $stateParams;
      vm.ctrlName = 'StudentsCtrl';
      vm.init();
    }
    init() {
      let vm = this;
      vm.User.profile({id: vm.$stateParams.id})
        .then((data) => {
          vm.student = data;
        });

      vm.User.studentProgress({id: vm.$stateParams.id})
        .then((data) => {
          vm.progress = data.progress_by_module;
        });
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.students.controller:StudentsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.students')
    .controller('StudentsCtrl', StudentsCtrl);
}());
